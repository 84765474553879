<template>
  <div v-if="shops">
    <CCard class="shadow-sm" style="border-radius:15px;width: 100%;min-width: 460px">
      <CCardBody class="m-4" style="min-width: 430px;">
        <h2 class="text-left font-weight-normal text-dark">
          {{ $t('currentUser') }}
        </h2>
        <hr />
        <br />
        <CRow>
          <CCol md="4">
            <CCardBody class="text-center">
              <div class="circle" v-if="shopImg === ''">
                <h3>{{ nameIcon }}</h3>
              </div>
              <img
                v-else
                alt=""
                class="img-fluid"
                width="50%"
                v-bind:src="shopImg"
                onerror="this.onerror=null;this.src='https://cdn-icons-png.flaticon.com/512/4472/4472453.png';"
              />
            </CCardBody>
            <h3 class="text-center font-weight-normal text-dark">
              {{ shopName }}
            </h3>
          </CCol>
          <CCol md="8">
            <table style="width:100%;" aria-describedby="">
              <tr class="h5 text-left">
                <th scope="col" class="font-weight-normal text-dark">
                  {{ $t('userDetail') }}
                </th>
                <td class="font-weight-normal text-grey">{{ $t('shopname') }}</td>
                <td class="text-right text-description">{{ shopName }}</td>
              </tr>
              <br />
              <tr class="h5 text-left">
                <td></td>
                <td class="font-weight-normal text-grey">{{ $t('email') }}</td>
                <td class="text-right text-description">{{ users.email }}</td>
              </tr>
              <br /><br />
              <tr class="h5 text-left">
                <td class="font-weight-normal text-dark">{{ $t('package') }}</td>
                <td class="font-weight-normal text-grey">
                  {{ $t('currentPlan') }}
                </td>
                <td class="text-right text-description">{{ currentPlan }}</td>
              </tr>
              <br />
            </table>
          </CCol>
        </CRow>

        <CButton
          @click="dangerModal = true"
          block
          color="danger"
          class="px-4 mt-4"
          variant="outline"
        >
          <CIcon name="cil-account-logout"></CIcon>  {{ $t('signOut') }}
        </CButton>
      </CCardBody>
    </CCard>

    <CModal
      :show.sync="dangerModal"
      :footer="footer"
      color="danger"
      :title="this.$i18n.t('signOut')"
      centered
    >
      <h4 class="text-center">{{ $t('dialogSignOut') }}</h4>
      <br />
      <h5 class="text-center">{{ $t('confirmSignOut') }}</h5>
      <template #footer-wrapper>
        <CRow class="justify-content-center">
          <CCol col="4" v-if="loadingButton === true">
            <CButton color="danger" @click="logout" block>{{ $t('confirm') }}</CButton>
          </CCol>
          <CCol col="4" v-else-if="loadingButton === false">
            <CButton color="danger" disabled block>
              <CSpinner color="white" size="sm" />
            </CButton>
          </CCol>
          <CCol col="4">
            <CButton color="dark" @click="dangerModal = false" block>
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>
        <br />
        <br />
      </template>
    </CModal>
  </div>
  <div v-else>
    <no-shop></no-shop>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import { mapGetters } from 'vuex'
import moment from 'moment'
import NoShop from '@/containers/NoShop'

export default {
  components: { NoShop },
  data() {
    return {
      dangerModal: false,
      footer: '',
      loadingButton: true,
      nameIcon: '',
    }
  },
  computed: {
    ...mapGetters(['users', 'shops']),
    shopImg() {
      if (this.shops.length !== 0) {
        return this.shops[0].remoteImagePath
      } else {
        return ''
      }
    },
    shopName() {
      if (this.users.shopName) {
        this.nameIcon = this.users.shopName.charAt(0).toUpperCase()
        return this.users.shopName
      } else {
        return '-'
      }
    },
    currentPlan() {
      let user = this.users.currentPlan
      if (user) {
        if (user == 'multiplePOS') {
          return 'SMART'
        } else if (user == 'starter') {
          return 'STARTER'
        } else if (user == 'standard') {
          return 'STANDARD'
        }
      }
      return '-'
    },
    start() {
      if (this.users.planUpgradeAt) {
        return moment(String(this.users.planUpgradeAt)).format(
          'DD MMMM YYYY HH:mm:ss'
        )
      } else {
        return '-'
      }
    },
    expire() {
      if (this.users.expire) {
        return moment(String(this.users.expire)).format('DD MMMM YYYY HH:mm:ss')
      } else {
        return '-'
      }
    },
  },
  beforeCreate() {
    this.$store.dispatch('getUser')
  },
  methods: {
    logout() {
      this.loadingButton = false
      firebase
        .auth()
        .signOut()
        .then(() => {
          // Sign-out successful.
          localStorage.removeItem('idToken')
          localStorage.removeItem('token')
          localStorage.removeItem('uid')
          localStorage.removeItem('shopObjectId')
          localStorage.removeItem('loginwith')
          window.location.href = '/login'
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>
.circle {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background-color: #acd7c6;
  border-radius: 50%;
}
.circle h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  color: #2f9576;
  font-size: 170px;
}
</style>
